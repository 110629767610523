<template>
  <div :class="type+'--alert mt-3'">
    <v-alert
      v-model="alert"
      dense
      outlined
      :type="type"
      dismissible
      close-text="Close Alert"
      class="mb-0 grey lighten-4"
    >
      {{msg}}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    errors: {
      default: () => ({}),
      type: [Object, String]
    },
    type: {
      default: 'error',
      type: String
    }
  },
  data () {
    return {
      alert: false,
      msg: ''
    }
  },
  watch: {
    errors: function (newValue) {
      this.showErrors(newValue);

    }
  },
  methods: {
    showErrors: function (errors) {
      if (typeof (errors) == "string") {
        this.msg = errors;
        this.alert = true;
        return 0;
      }
      const error_keys = Object.values(errors);
      const fields = error_keys[0];

      if (!fields) {
        this.alert = false;
        return 0;
      }

      if (typeof (fields) == "string") {
        this.msg = fields;
      } else if (Array.isArray(fields)) {
        const errors_fields = fields[0];
        this.msg = errors_fields;
      } else {
        const errors_fields = Object.entries(fields)[0];
        this.msg = `${errors_fields[0]}: ${errors_fields[1].join()}`
      }
      this.alert = true;
    }
  }
}

</script>